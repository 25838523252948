import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

export default function Footer() {
    const navigateContact = () => {
        window.location.href = '/contactus';
    }

    const chatNow = () => {
        window.open('https://tawk.to/chat/6504cf82b1aaa13b7a771bee/1hadcl5qf', 'chat', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=603,height=510')
    }

    return (
        <>
            <Row className="footer">
                {/*<Row>*/}
                {/*    <Col sm={11}>*/}
                {/*        <p className="footer-heading-top">Still Need Assistance? If you have additional questions related to <span className="bold">Kawasaki Parts and Accessories Orders</span>, use one of the support options below:</p>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row>
                    <Col sm={3} className="footer-col footer-first">
                        <Row className="footer-title keep-layout">
                            <Col xl={6} lg={12}>
                                <h4> LIVE CHAT SUPPORT</h4>
                            </Col>
                            <Col xl={6} lg={12}>
                                <Button variant="primary" onClick={chatNow}>
                                    <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                    </svg>
                                    CHAT NOW
                                </Button>
                            </Col>
                        </Row>
                        <Row className="footer-top-text footer-text">
                            <p>Live Chat operators for Order Support are available Monday - Friday, 8:00am - 5:00pm PST</p>
                        </Row>
                        {/*<Row className="footer-text justify-content-center">*/}
                        {/*    <Col sm={12} xl={6} className="button-col">*/}
                        {/*        <img src="https://devbmsstore.ses-bms.com/images/chatnow.png" alt="Chat Now chat bubble"></img>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="footer-col">
                        <Row className="footer-title keep-layout">
                            <Col xl={6} lg={12}>
                                <h4>CONTACT US</h4>
                            </Col>
                            <Col xl={6} lg={12}>
                                <Button variant="primary" onClick={navigateContact}>
                                    <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                    </svg>
                                    CONTACT US
                                </Button>
                            </Col>
                        </Row>
                        <Row className="footer-top-text footer-text">
                            <p>Submit an Order Support ticket</p>
                        </Row>
                    </Col>
                    <Col sm={{ span: 4, offset: 1 }} className="footer-col">
                        <Row className="footer-title keep-layout">
                            <Col xl={12} lg={12}>
                                <h4>CUSTOMER SERVICE</h4>
                            </Col>
                        </Row>
                        <Row className="footer-top-text footer-bottom-text">
                            <Col xl={3} lg={5}>
                                <p className="footer-customer">Orders</p>
                            </Col>
                            <Col xl={4} lg={7}>
                                <p className="footer-customer"><a className="phone-link" href="tel:+18555543344">1 (855) 554-3344</a></p>
                            </Col>
                            <Col xl={5} lg={12}>
                                <p className="footer-customer-end footer-phone">Monday - Friday <br></br> 8:00am - 5:00pm PST</p>
                            </Col>
                            <Col xl={3} lg={5}>
                                <p className="footer-customer">Product Questions</p>
                            </Col>
                            <Col xl={4} lg={7}>
                                <p className="footer-customer"><a className="phone-link" href="tel:+18668029381">1 (866) 802-9381</a></p>
                            </Col>
                            <Col xl={5} lg={12}>
                                <p className="footer-phone">Monday - Friday <br></br> 7:00am - 4:00pm PST</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
        </>
    )
}