import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';
export default function OrderItem() {

    useEffect(() => {
        document.title = 'Kawasaki.com Help Center';
    }, []);

    //const [details, setDetails] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const [orderItem, setOrderItem] = useState(null);
    const [returnReasons, setReturnReasons] = useState(null);
    const [count, setCount] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.screen.width);

    useEffect(() => {
        if (!location.state || !location.state.id) {
            navigate('/helpcenter');
            return;
        }
        GetReturnDetail();
    }, [location])

    const GetReturnDetail = async () => {
        try {
            const response = await fetch("helpcenter/GetReturnDetail/" + location.state.id);
            if (response.status != 200) {
                throw new Error(response.statusText);
            }
            else {
                const js = await response.json();
                console.log(js);
                setOrderItem(js.items);
                setReturnReasons(js.reasons);
            }
        }
        catch (e) {
            console.log(e);
        }
    };


    const navigateOrder = () => {
        window.location.href = '/orderitem';
    }
    const handleQtyChange = (index, event) => {
        debugger;
        let data = [...orderItem];
        data[index][event.target.name] = event.target.value;
        setOrderItem(data);
    }

    const handleQtyChangeMobile = (index, event) => {
        debugger;
        let data = [...orderItem];
        data[index][event.target.name] = event.target.value;
        setOrderItem(data);
    }

    const handleCommentsChange = (index, event) => {
        debugger;
        event.target.value = event.target.value.replace(/[^\w\s]/gi, "");
        handleQtyChange(index, event);
        count[index] = event.target.value.length;
        //setCount(event.target.value.length)
    }

    const handleCommentsChangeMobile = (index, event) => {
        debugger;
        event.target.value = event.target.value.replace(/[^\w\s]/gi, "");
        handleQtyChangeMobile(index, event);
        count[index] = event.target.value.length;
        //setCount(event.target.value.length)
    }

    const handleCheckChange = (index, event) => {
        debugger;
        $(`#qty_${event.target.id}`).prop("disabled", !event.target.checked);
        $(`#reason_${event.target.id}`).prop("disabled", !event.target.checked);
        $(`#comments_${event.target.id}`).prop("disabled", !event.target.checked);
    }

    const handleCheckChangeMobile = (index, event) => {
        debugger;
        $(`#qtym_${event.target.id}`).prop("disabled", !event.target.checked);
        $(`#reasonm_${event.target.id}`).prop("disabled", !event.target.checked);
        $(`#commentsm_${event.target.id}`).prop("disabled", !event.target.checked);
    }

    $(window).resize(function () {
        setScreenWidth(window.screen.width);
    });

    const submitBtn = $('#btnSubmitStep1');
    
    const handleSubmitOne = async () => {
        try {
            //validation
            debugger;
            let array = [];
            let err = false;
            if ($('input:checkbox:checked').length == 0) {
                $('#pError').text('Please select the item(s) that you are returning.');
                return;
            }
            submitBtn.prop('disabled', true);
            $('input:checkbox').each(function (index) {
                if (this.checked) {
                    let item = orderItem[index];
                    const id = $(this).val();
                    const iQty = parseInt(item.qty.trim());
                    const avlbQty = $('#avbqty_' + id).text();
                    const iAvlbQty = parseInt(avlbQty);
                    const invalidFormClass = 'form-control is-invalid';
                    const invalid = 'is-invalid';
                    const qtyId = '#qty_' + id;
                    const qtyIdMobile = '#qtym_' + id;
                    if (item.qty.length == 0 || iQty == 0 || iQty > iAvlbQty) {
                        $(qtyId).addClass(invalidFormClass);
                        $(qtyIdMobile).addClass(invalidFormClass);
                        err = true;
                    }
                    else {
                        $(qtyId).removeClass(invalid);
                        $(qtyIdMobile).removeClass(invalid);
                    }
                    const rsId = '#reason_' + id;
                    const rs = (screenWidth > 767 ? $(rsId).val().trim() : '');

                    const rsIdMobile = '#reasonm_' + id;
                    const rsMobile = (screenWidth <= 767 ? $(rsIdMobile).val().trim() : '');

                    if (rs == "" && rsMobile === "") {
                        $(rsId).addClass(invalidFormClass);
                        $(rsIdMobile).addClass(invalidFormClass);
                        err = true;
                    }
                    else {
                        $(rsId).removeClass(invalid);
                        $(rsIdMobile).removeClass(invalid);
                    }
                    const cmsId = '#comments_' + id;
                    const cmsIdMobile = '#comments_' + id;
                    if (item.comments.trim() == "") {
                        $(cmsId).addClass(invalidFormClass);
                        $(cmsIdMobile).addClass(invalidFormClass);
                        err = true;
                    }
                    else {
                        $(cmsId).removeClass(invalid);
                        $(cmsIdMobile).removeClass(invalid);
                    }
                    if (screenWidth > 767) {
                        array.push({ id: id, qty: item.qty, reason: rs, comments: item.comments });
                    } else {
                        array.push({ id: id, qty: item.qty, reason: rsMobile, comments: item.comments });
                    }
                }
            });
            if (err) {
                $('#pError').text('Please correct the highlighted fields above.');
                return;
            }
            //submit
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({ orderID: location.state.id, itms: array })
            };
            const resOrder = await fetch('helpcenter/InitiateReturn', requestOptions);
            if (resOrder.status !== 200) {
                document.getElementById('pError').innerHTML = resOrder.statusText;
                submitBtn.prop('disabled', false);
                throw new Error(resOrder.statusText);
            }
            const js = await resOrder.json();
            submitBtn.prop('disabled', false);
            navigate('/confirmreturn', { state: { id: js.value1 } });
        }
        catch (e) {
            console.log(e);
        }


    }

    return (
        <>
            <Container className="checkout-container main-container">
                <Container>
                    <Card className="help-card">
                        <Row className="title-group title-group-active return-title-row">
                            <Col sm={4} className="return-title-col return-details">
                                <Card.Title className="vertical-line heading card-main-title active-heading return-title contact-title" role="heading">RETURN DETAILS</Card.Title>
                            </Col>
                            <Col sm={{ span: 1, offset: 7 }}>
                                <p className="underline order-back contact-back" onClick={navigateOrder} aria-label="Link to go back to the Help Center page" tabIndex="0">Back</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={5} sm={12}>
                                <p className="return-text">Please select the item(s) that you are returning.</p>
                            </Col>
                        </Row>
                        {screenWidth > 767 ?  
                            <Row className="order-table non-mobile">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Image</th>
                                            <th>Details</th>
                                            <th>Available Qty to Return</th>
                                            <th>Return Quantity</th>
                                            <th>Price</th>
                                            <th>Return Reason</th>
                                            <th>Return Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderItem ? orderItem.map((d, idx) =>
                                            <>
                                                <tr className="">
                                                    <td><Form.Check type="checkbox" name="return_items" aria-label={`Return Item ${idx + 1}`} id={d.partID} value={d.partID} onChange={event => handleCheckChange(idx, event)} disabled={d.elecktrod} /></td>
                                                    <td className="table-img"><img src={d.img} alt="Product" className="item-image"></img></td>
                                                    <td className="table-left">
                                                        <p>{d.description}</p>
                                                        <p>SKU:{d.partID}</p>
                                                    </td>
                                                    <td id={`avbqty_${d.partID}`}>{d.availableQty}</td>
                                                    <td className="return-quantity">
                                                        <Form.Control type="number" name="qty" id={`qty_${d.partID}` } onChange={event => handleQtyChange(idx, event)} min="1" max="100000" disabled/>
                                                    </td>
                                                    <td>{d.unitPrice}</td>
                                                    <td className="return-reason">
                                                        <FloatingLabel
                                                            controlId={`reason_${d.partID}`}
                                                            label="Return Reason"
                                                        >
                                                            <Form.Select aria-label="Floating label return reason" disabled>
                                                                <option value="">Select a reason</option>
                                                                {returnReasons.map(op => (
                                                                    <><option value={op.code}>{op.description}</option></>
                                                                ))}
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </td>
                                                    <td className="return-comments">
                                                        <FloatingLabel label="Comments">
                                                            <Form.Control
                                                                id={`comments_${d.partID}`}
                                                                as="textarea"
                                                                name="comments"
                                                                placeholder="Leave a comment here"
                                                                style={{ height: '100px' }}
                                                                maxLength="100"
                                                                onChange={event => handleCommentsChange(idx, event)}
                                                                disabled
                                                            />
                                                            <p>{count[idx] ? count[idx]: 0}/100 Characters</p>
                                                        </FloatingLabel>
                                                    </td>
                                                </tr>
                                                {d.elecktrod ?
                                                    <tr>
                                                        <td colspan={8} className="red" style={{ color: 'red', textAlign: 'left' }}>
                                                            Elektrode&#174; electric bike orders are not returnable online. Please contact our <a href="/contactus" className="red bold">Help Center</a> to initate a return.
                                                        </td>
                                                    </tr>
                                                :
                                                    <></>
                                                }
                                            </>
                                        ) : "Loading"}

                                    </tbody>
                                </Table>
                            </Row>
                        :
                            <Row className="mobile-only order-table">
                                {orderItem ? orderItem.map((d, idx) =>
                                    <>
                                        <Table hover responsive>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <td>
                                                        <Form.Check type="checkbox" name="return_items" aria-label={`Return Item ${idx + 1}`} id={d.partID} value={d.partID} disabled={d.elecktrod} onChange={event => handleCheckChangeMobile(idx, event)} />
                                                    </td>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th>Image</th>
                                                    <td className="table-img"><img src={d.img} alt="Product" className="item-image"></img></td>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th>Details</th>
                                                    <td className="table-left">
                                                        <p>{d.description}</p>
                                                        <p>SKU:{d.partID}</p>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th>Available Qty to Return</th>
                                                    <td id={`avbqty_${d.partID}`}>{d.availableQty}</td>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th>Return Quantity</th>
                                                    <td className="return-quantity">
                                                        <Form.Control type="number" name="qty" id={`qtym_${d.partID}`} onChange={event => handleQtyChangeMobile(idx, event)} min="1" max="100000" disabled />
                                                    </td>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th>Price</th>
                                                    <td>{d.unitPrice}</td>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th>Return Reason</th>
                                                    <td className="return-reason">
                                                        <FloatingLabel
                                                            controlId={`reasonm_${d.partID}`}
                                                            label="Return Reason"
                                                        >
                                                            <Form.Select aria-label="Floating label return reason" disabled>
                                                                <option value="">Select a reason</option>
                                                                {returnReasons.map(op => (
                                                                    <><option value={op.code}>{op.description}</option></>
                                                                ))}
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th>Return Comments</th>
                                                    <td className="return-comments">
                                                        <FloatingLabel label="Comments">
                                                            <Form.Control
                                                                id={`commentsm_${d.partID}`}
                                                                as="textarea"
                                                                name="comments"
                                                                placeholder="Leave a comment here"
                                                                style={{ height: '100px' }}
                                                                maxLength="100"
                                                                onChange={event => handleCommentsChangeMobile(idx, event)}
                                                                disabled
                                                            />
                                                            <p>{count[idx] ? count[idx] : 0}/100 Characters</p>
                                                        </FloatingLabel>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {d.elecktrod ?
                                                    <>
                                                        <tr>
                                                            <td colspan={2} className="red" style={{ color: 'red', textAlign: 'left' }}>
                                                                Elektrode&#174; electric bike orders are not returnable online. Please contact our <a href="/contactus" className="red bold">Help Center</a> to initate a return.
                                                            </td>
                                                        </tr>
                                                        <td></td>
                                                    </>
                                                    :
                                                    <td></td>
                                                }
                                            </tbody>
                                        </Table>
                                    </>
                                ) :
                                    <p>Loading</p>
                                }
                            </Row>
                        }
                        <Row>
                            {<p className="error-msg" id="pError"></p>}
                        </Row>
                        <Row className="return-btn">
                            <Col sm={{ span: 3, offset: 9 }} className="return-submit-btn-col">
                                <Button variant="primary" className="" onClick={handleSubmitOne} type="submit" id="btnSubmitStep1">
                                    <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                    </svg>
                                    CONTINUE
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Container>
            </Container>
        </>
    )
}