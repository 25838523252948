import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState, useRef } from "react";
import InputMask from "react-input-mask";
import useExternalScripts from "../useExternalScripts";
import useGooglePayScripts from "../useGooglePayScripts";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';

//import { ErrorMessage } from "@hookform/error-message"
export default function CheckoutStep2({ USStates, id, shippingInfo, onDeliveryOptionChangeFn, freightFee, priceTotal, dealerid }) {
    const validationSchema = Yup.object().shape({
        //userSession: Yup.string().required(),
        payment_type: Yup.string().required(),
        //fullName: Yup.string().required(),
        //cardNum: Yup.string().required(),
        //expirationDate: Yup.string().required().transform(value => value.replace(/[^\d]/g, '')).min(4).max(4),
        //cc: Yup.number().required(),
        sameAsShipping: Yup.boolean(),
        fullName: Yup
            .string()
            .when("payment_type", {
                is: "0",
                then: () => Yup.string().trim().required().max(255)
            }),
        cardNum: Yup
            .string()
            .when("payment_type", {
                is: "0",
                then: () => Yup.string().required().min(13).max(16)
            }),
        expirationDate: Yup
            .string()
            .when("payment_type", {
                is: "0",
                then: () => Yup.string().required().transform(value => value.replace(/[^\d]/g, '')).min(4).max(4)
            }),
        cc: Yup
            .string()
            .when("payment_type", {
                is: "0",
                then: () => Yup.string().required().min(3).max(4)
            }),
        billingCity: Yup
            .string()
            .when("sameAsShipping", {
                is: false,
                then: () => Yup.string().trim().required()
            }),
        billingZip: Yup
            .string()
            .when("sameAsShipping", {
                is: false,
                then: () => Yup.string().trim().required()
            }),
        billingStateKey: Yup
            .string()
            .when("sameAsShipping", {
                is: false,
                then: () => Yup.string().required()
            }),
        billingAddress: Yup
            .string()
            .when("sameAsShipping", {
                is: false,
                then: () => Yup.string().trim().required()
            }),
        billingTerm:
            Yup.boolean().oneOf([true])

    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, getValues, reset, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [UserSession, setUserSession] = useState([]);
    const [BillingStates, setBillingStates] = useState([]);
    const [ShippingAddress, setShippingAddress] = useState([]);
    const [paymentError, setPaymentError] = useState('');
    const [show, setShow] = useState(false);
    const [pop, setPop] = useState(false);
    const [modal, setModal] = useState(false)

    const [totalCharge, setTotalCharge] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setPop(false);
    };

    const handlePop = () => setPop(true);
    const handlePopClose = () => setPop(false);

    const handleModal = () => {
        setPop(false);
        setModal(true);
    };
    const handleModalClose = () => setModal(false);


    //// ---------------------------------------------Start Authorize.NET GooglePay-----------------------------------------------------------------

    const baseRequest = {
        apiVersion: 2,
        apiVersionMinor: 0
    };
    const allowedCardNetworks = ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"];

    const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
    const Testmode = () => {
        return window.location.origin.indexOf("shop") == -1;
        //return window.location.origin.indexOf("sb-") == -1;
    };

    const tokenizationSpecification = {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            'gateway': 'authorizenet',
            'gatewayMerchantId': Testmode() ? '875918' : "2610387"
        }
    };

    const baseCardPaymentMethod = {
        type: 'CARD',
        parameters: {
            allowedAuthMethods: allowedCardAuthMethods,
            allowedCardNetworks: allowedCardNetworks
        }
    };

    const cardPaymentMethod = Object.assign(
        {},
        baseCardPaymentMethod,
        {
            tokenizationSpecification: tokenizationSpecification
        }
    );


    let paymentsClient = null;

    function getGoogleIsReadyToPayRequest() {
        return Object.assign(
            {},
            baseRequest,
            {
                allowedPaymentMethods: [baseCardPaymentMethod]
            }
        );
    }


    function getGooglePaymentDataRequest(priceTotal, data) {
        const paymentDataRequest = Object.assign({}, baseRequest);
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo(priceTotal);
        paymentDataRequest.merchantInfo = {
            // @todo a merchant ID is available for a production environment after approval by Google
            // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
            merchantId: 'BCR2DN4TZ3KITUS3',
            merchantName: 'Kawasaki.com'
        };

        paymentDataRequest.callbackIntents = ["PAYMENT_AUTHORIZATION"];

        return paymentDataRequest;
    }

    function getGooglePaymentsClient(data) {
        if (paymentsClient === null) {
            paymentsClient = new window.google.payments.api.PaymentsClient({
                environment: Testmode() ? 'TEST' : "PRODUCTION",
                paymentDataCallbacks: {
                    onPaymentAuthorized: onPaymentAuthorized
                }
            });
        }
        return paymentsClient;
    }
    async function onPaymentAuthorized(paymentData) {
        debugger;
        /*        return alert(paymentData.paymentMethodData.tokenizationData.token);*/
        //return new Promise(function (resolve, reject) {
        //    // handle the response
        //    processPayment(paymentData)
        //        .then(function () {
        //            resolve({ transactionState: 'SUCCESS' });
        //        })
        //        .catch(function () {
        //            resolve({
        //                transactionState: 'ERROR',
        //                error: {
        //                    intent: 'PAYMENT_AUTHORIZATION',
        //                    message: 'Insufficient funds, try again. Next attempt should work.' + paymentData.paymentMethodData.tokenizationData.token,
        //                    reason: 'PAYMENT_DATA_INVALID'
        //                }
        //            });
        //        });
        //});
        const res = await processPayment(paymentData);
        if (res) {
            return { transactionState: 'SUCCESS' };
        }
        else {
            return {
                transactionState: 'ERROR',
                error: {
                    intent: 'PAYMENT_AUTHORIZATION',
                    message: 'Insufficient funds, try again. Next attempt should work.' + paymentData.paymentMethodData.tokenizationData.token,
                    reason: 'PAYMENT_DATA_INVALID'
                }
            };
        }
    }


    function onGooglePayLoaded() {
        const paymentsClient = getGooglePaymentsClient();
        paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
            .then(function (response) {
                if (response.result) {
                    addGooglePayButton();
                }
            })
            .catch(function (err) {
                // show error in developer console for debugging
                console.error(err);
            });
    }


    function addGooglePayButton() {
        const paymentsClient = getGooglePaymentsClient();
        const button = paymentsClient.createButton({ onClick: onGooglePaymentButtonClicked });
        const ph = document.getElementById("container");
        ph.appendChild(button);
    }


    function getGoogleTransactionInfo() {
        return {
            countryCode: 'US',
            currencyCode: "USD",
            totalPriceStatus: "FINAL",
            totalPrice: priceTotal.toString(),
            totalPriceLabel: "Total"
        };
    }

    function onGooglePaymentButtonClicked() {
        debugger;
        const paymentDataRequest = getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

        const paymentsClient = getGooglePaymentsClient();
        /* paymentsClient.loadPaymentData(paymentDataRequest);*/
        paymentsClient.loadPaymentData(paymentDataRequest).then(function (paymentData) {
            debugger;
            // if using gateway tokenization, pass this token without modification
            window.paymentToken = paymentData.paymentMethodData.tokenizationData.token;
        }).catch(function (err) {
            // show error in developer console for debugging
            /*    console.error(err);*/
        });
    }


    async function processPayment(paymentData) {
        /*        alert(paymentData.paymentMethodData.tokenizationData.token);*/
        window.paymentToken = paymentData.paymentMethodData.tokenizationData.token;
        var enc = window.btoa(paymentData.paymentMethodData.tokenizationData.token);
        digitalWalletData.token = enc;
        digitalWalletData.dealerId = dealerid;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(digitalWalletData)
        };
        const resOrder = await fetch('checkouth/SubmitOrderAuthorizeNetDigital', requestOptions);
        if (resOrder.status !== 200) {
            //document.getElementById('error2').innerHTML = resOrder.statusText;
            //if (resOrder.status === 401) {
            //    setPaymentError("There was an error with verifying your Credit/Debit Card.");
            //    handleShow();

            //}
            //else {
            //    document.getElementById('error2').innerHTML = resOrder.statusText;
            //}
            handlePopClose();
            return false;
            //throw new Error(resOrder.statusText);
        }
        const js = await resOrder.json();
        //resolve({ transactionState: 'SUCCESS' });
        debugger;
        navigate('/confirmpage', { state: { id1: js.value1, id2:js.value2 } });
        return true;
    }
    ////--------------------------------- End Authorize.Net Google Pay---------------------------------------------------------------------------------


    //const [OrderID, setOrderID] = useState('');
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');
    console.log({ errors });
    var scriptURL = window.location.origin.indexOf("shop") == -1 ? "https://pay.google.com/gp/p/js/pay.js" : "https://pay.google.com/gp/p/js/pay.js"
    useExternalScripts("https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js");
    useGooglePayScripts(scriptURL);
    var ReCaptchascriptURL = "https://www.google.com/recaptcha/api.js";
    useExternalScripts(ReCaptchascriptURL);
    let billing = document.getElementById('billing-address')
    let sameCheck = document.getElementById('sameShippingGroup')
    let checkSame = document.getElementById('sameShipping')
    const loader = document.getElementById('loader')
    const { isDirty, isValid } = formState;
    const showBilling = () => {
        if (checkSame.checked) {
            billing.classList.add('hide-items')
        } else {
            billing.classList.remove('hide-items')
        }
    }

    const ccFields = useRef(null)

    const ccSelect = () => {
        if (ccFields.current.classList.contains('hide-items')) {
            ccFields.current.classList.remove('hide-items');
            $('#rowRecaptcha').show();
        }
    }

    const altSelect = () => {
        if (!ccFields.current.classList.contains('hide-items')) {
            ccFields.current.classList.add('hide-items');
            $('#rowRecaptcha').hide();
        }
    }
    let digitalWalletData;
    const onSubmit = async (data) => {
        try {
            console.log(data);
            //Google Recaptcha

            //let token = $('#g-recaptcha-response').val();
            //const err = 'There was an error with verifying your order, please try again.';
            //if (token == "") {
            //    setErrorMsg("Please complete the Captcha verification by clicking the checkbox.");
            //    return;
            //}
            //const RecaptChaToken = {
            //    method: 'POST',
            //    headers: {
            //        'Content-type': 'application/json'
            //    },
            //    body: JSON.stringify({ token: token })
            //};
            //const res = await fetch('checkouth/ReCaptchaToken', RecaptChaToken);
            //const js = await res.json();
            //if (res.status !== 200) {
            //    console.log(js.value1);
            //    setErrorMsg(err);
            //    throw new Error(res.statusText);
            //}
            //else {

            //save order into DB
            if (!UserSession || !ShippingAddress || !priceTotal) {
                navigate('/sessionexpired', { state: { id: 2 } });
                return;
            }

            if (data.payment_type == "0") {
                //Google Recaptcha
                let token = $('#g-recaptcha-response').val();
                const err = 'There was an error with verifying your order, please try again.';
                if (token == "") {
                    setErrorMsg("Please complete the Captcha verification by clicking the checkbox.");
                    return;
                }
                const RecaptChaToken = {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({ token: token })
                };
                const res = await fetch('checkouth/ReCaptchaToken', RecaptChaToken);
                const jsCap = await res.json();
                if (res.status !== 200) {
                    console.log(jsCap.value1);
                    setErrorMsg(err);
                    throw new Error(res.statusText);
                }
                handlePop();
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({ id: UserSession, paymentIf: data, dealerId: dealerid })
                };
                // Add "https://ipinfo.io" statement
                // this will communicate with the ipify servers
                // in order to retrieve the IP address

                const resOrder = await fetch('checkouth/SubmitOrderAuthorizeNet', requestOptions);
                if (resOrder.status !== 200) {
                    //document.getElementById('error2').innerHTML = resOrder.statusText;
                    if (resOrder.status === 401) {
                        setPaymentError("There was an error with verifying your Credit/Debit Card.");
                        handleShow();

                    }
                    else {
                        document.getElementById('error2').innerHTML = resOrder.statusText;
                    }
                    handlePopClose();
                    throw new Error(resOrder.statusText);
                }
                const js = await resOrder.json();

                navigate('/confirmpage', { state: { id1: js.value1 , id2: js.value2 } });
                return;
            }
            else if (data.payment_type == "1") {

                //LoadPaypalButton(priceTotal, data);
                handleModal();
                handlePopClose();
            }
            else if (data.payment_type == "2") {

                //LoadApplePayButton(priceTotal, data);
                handleModal();
                handlePopClose();
            }
            else if (data.payment_type == "3") {
                debugger;
                /*LoadGooglePayButton(priceTotal, data);*/
                digitalWalletData = {
                    id: UserSession,
                    //payment_type: data.payment_type,
                    //deliveryOption: data.deliveryOption,
                    //subscribe: data.subscribe,
                    //sameAsShipping: data.sameAsShipping,
                    paymentIf: data,
                    token: ""
                }
                onGooglePayLoaded();
                handleModal();
                handlePopClose();
            }
            else if (data.payment_type == "4") {
                //CLICK TO PAY BUTTON
                //LoadClickToPayButton(priceTotal, data);
                /*    onGooglePayLoaded();*/
                handleModal();
                handlePopClose();
            }
            //else if (data.payment_type == "5") {
            //    LoadGooglePayButton();
            //}
            //}

            //return;
        } catch (e) {
            console.log(e);
            // handle your error
            return;
        }
    }
    useEffect(() => {
        let termsText = document.getElementById('terms').parentNode.childNodes[1]
        termsText.innerHTML = `
            <span class="red">*</span> I VERIFY THAT I AM 13 OR OVER, A UNITED STATES RESIDENT, AND AGREE TO KAWASAKI'S PRIVACY POLICY AND TERMS AND CONDITIONS OF USE`
    }, [id])
    useEffect(() => {
        setBillingStates(USStates);
        setUserSession(id);
        setShippingAddress(shippingInfo);

    }, [USStates, id, shippingInfo])
    useEffect(() => {
        if (priceTotal) {
            setTotalCharge(priceTotal);
        }
    }, [priceTotal])
    const deliveryOption = register("deliveryOption");
    const sameAsShipping = register("sameAsShipping");
    const payment_type = register("payment_type");
    let creditIcon = <img src="https://devbmsstore.ses-bms.com/images/credit-card.png" alt="Credit card icon" className="payment-icon"></img>

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="review-last-heading">
                <Col sm={5}>
                    <h4 className="heading">DELIVERY OPTIONS</h4>
                </Col>
                <Col sm={6}>
                    <Form.Select {...register("deliveryOption")} aria-label="Select delivery options" onChange={(e) => {
                        deliveryOption.onChange(e);
                        if (onDeliveryOptionChangeFn) {
                            onDeliveryOptionChangeFn(getValues("deliveryOption"));
                        }
                    }}>
                        {/*<option value="1">UPS - STANDARD ($6.95: 5-7 BUSINESS DAYS) </option>*/}
                        {/*<option value="2">UPS - EXPEDITED ($21.95: 2-4 BUSINESS DAYS)</option>*/}
                        {/*  <option value="1">UPS - STANDARD (${freightFee[0]}: 5-7 BUSINESS DAYS) </option>*/}
                        <option value="1">UPS - STANDARD (${freightFee[0] == 0 ? "0.00" : freightFee[0]}: 5-7 BUSINESS DAYS) </option>
                        <option value="2">UPS - EXPEDITED (${freightFee[1]}: 2-4 BUSINESS DAYS)</option>
                    </Form.Select>

                </Col>
            </Row>
            <hr></hr>
            <div className="billing-row">
                <div className="">
                    <h4 className="heading">BILLING ADDRESS</h4>
                    <Form.Group className="group mb-3 indent-left" id="sameShippingGroup">
                        <Form.Check className="heading checkbox" type="checkbox" id="sameShipping" onChange={e => {
                            sameAsShipping.onChange(e);
                        }} {...register("sameAsShipping")} label="BILLING ADDRESS SAME AS SHIPPING ADDRESS" onClick={showBilling} />
                    </Form.Group>
                    <div id="billing-address">
                        <p className="lighter-text spacing-left">Required Fields*</p>
                        <Row className="group">
                            <Col sm={5}>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <FloatingLabel controlId="floatingAddress" label="Address*" className="mb-3" >
                                            <Form.Control type="text" placeholder="Address*" onChangeText={onChange} onBlur={onBlur} value={value} {...register("billingAddress")} className={`form-control ${errors.billingAddress ? 'is-invalid' : ''}`} />
                                        </FloatingLabel>
                                    )}
                                    name="billingAddress"
                                    aria-required="true"
                                />
                            </Col>
                            <Col sm={5}>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <FloatingLabel controlId="floatingCity" label="City*" className="mb-3" >
                                            <Form.Control type="text" placeholder="City*" onChangeText={onChange} onBlur={onBlur} value={value} {...register("billingCity")} className={`form-control ${errors.billingCity ? 'is-invalid' : ''}`} />
                                        </FloatingLabel>
                                    )}
                                    name="billingCity"
                                    aria-required="true"
                                />
                            </Col>
                        </Row>
                        <Row className="group bottom-group">
                            <Col sm={5}>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <FloatingLabel controlId="floatingStates" label="State*" className="mb-3" aria-label="Select a state">
                                            <Form.Control
                                                as="select"
                                                custom
                                                {...register("billingStateKey")} className={`form-control ${errors.billingStateKey ? 'is-invalid' : ''}`}
                                                onChangeText={onChange} onBlur={onBlur} value={value}
                                            >
                                                <option value="">Select a state</option>
                                                {BillingStates ? BillingStates.map(op => (
                                                    <><option value={op.value}>{op.label}</option></>
                                                )) : ""}
                                            </Form.Control>
                                        </FloatingLabel>
                                    )}
                                    name="billingStateKey"
                                    aria-required="true"
                                />
                            </Col>
                            <Col sm={5}>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <FloatingLabel controlId="floatingZipCode" label="Zip Code*" className="mb-3" >
                                            <Form.Control type="number" placeholder="Zip Code*" onChangeText={onChange} onBlur={onBlur} value={value} {...register("billingZip")} className={`form-control ${errors.billingZip ? 'is-invalid' : ''}`} maxLength="20" />
                                        </FloatingLabel>
                                    )}
                                    name="billingZip"
                                    aria-required="true"
                                />
                            </Col>

                            </Row>
                    </div>
                </div>
            </div>
            <Row>
                <h4 className="heading payment-heading">PAYMENT</h4>
            </Row>
            <Row>
                <Col>
                    <Form.Check defaultChecked className="heading payment-type" label={<span><img src="/images/credit-card.png" alt="Credit card icon"
                        className="payment-icon"></img> CREDIT CARD <span className="red debit-info">* Debit Cards not accepted</span></span>} name="payment_method" type="radio" id="creditDebit" value={0} onClick={ccSelect}
                        onChange={e => {
                            payment_type.onChange(e);
                        }}
                        {...register("payment_type")} />
                </Col>
            </Row>
            <div ref={ccFields}>
                <Row className="">
                    <Col lg={5} sm={6}>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FloatingLabel controlId="floatingCardName" label="Name on the card*" className="mb-3" >
                                    <Form.Control type="text" placeholder="Name on the card*" onChangeText={onChange} onBlur={onBlur} value={value} {...register("fullName")} className={`form-control ${errors.fullName ? 'is-invalid' : ''}`} />
                                </FloatingLabel>
                            )}
                            name="fullName"
                            aria-required="true"
                        />
                    </Col>
                    <Col lg={5} sm={6}>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FloatingLabel controlId="floatingCardNumber" label="Credit Card Number*" className="mb-3" >
                                    <Form.Control type="number" placeholder="Credit Card Number*"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChangeText={onChange} onBlur={onBlur} value={value} {...register("cardNum")} className={`form-control ${errors.cardNum ? 'is-invalid' : ''}`} />
                                </FloatingLabel>
                            )}
                            name="cardNum"
                            aria-required="true"
                        />
                    </Col>
                </Row>
                <Row className="">
                    <Col lg={5} sm={6}>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FloatingLabel controlId="floatingExpiration" label="Expiration MM/YY*" className="mb-3" >
                                    <InputMask
                                        mask="99/99"
                                        value={value}
                                        type={'text'}
                                        inputMode="numeric"
                                        onChange={onChange}
                                        {...register("expirationDate")} className={`form-control ${errors.expirationDate ? 'is-invalid' : ''}`}
                                    >
                                    </InputMask>
                                </FloatingLabel>
                            )}
                            name="expirationDate"
                            aria-required="true"
                        />
                    </Col>
                    <Col lg={5} sm={6}>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FloatingLabel controlId="floatingCode" label="Security Code*" className="mb-3" >
                                    <Form.Control type="number" placeholder="Security Code*" onChangeText={onChange} onBlur={onBlur} value={value} {...register("cc")} className={`form-control ${errors.cc ? 'is-invalid' : ''}`} maxLength="4" />
                                </FloatingLabel>
                            )}
                            name="cc"
                            aria-required="true"
                        />
                    </Col>
                    <Col sm={8}></Col>
                    <Col sm={3} className="tooltips">
                        <p className="lighter-text underline what-text">What's this?
                            <span className="tooltiptext"><img src="/images/cc-code.png" alt="Credit Card Security Code location"></img></span>
                        </p>
                    </Col>
                </Row>
            </div>

            <Row className="payment-row">
                {/*<Col sm={5}>*/}
                {/*    */}{/*    <Form.Check inline className="heading payment-type" label={<span><img src="https://devbmsstore.ses-bms.com/images/paypal.png" alt="Credit card icon" className="payment-icon paypal"></img> PAYPAL</span>} name="payment_method" type="radio" id="paypal" value={1} onClick={LoadPaypalButton} {...register("payment_type")} />*/}
                {/*    <Form.Check inline className="heading payment-type" label={<span><img src="/images/paypal.jpg" alt="Paypal icon" className="payment-icon paypal"></img> PAYPAL</span>} name="payment_method" type="radio" id="paypal"*/}
                {/*        value={1} onClick={altSelect}*/}
                {/*        onChange={e => {*/}
                {/*            payment_type.onChange(e);*/}
                {/*        }}*/}
                {/*        {...register("payment_type")} />*/}
                {/*</Col>*/}
                {/*<Col sm={5} className="">*/}
                {/*    <Form.Check inline className="heading payment-type" label={<span><img src="/images/click-to-pay.png" alt="Click To Pay icon" className="payment-icon"></img> CLICK TO PAY</span>}*/}
                {/*        name="payment_method" type="radio" id="clickPay" value={4} onClick={altSelect}*/}
                {/*        onChange={e => {*/}
                {/*            payment_type.onChange(e);*/}
                {/*        }}*/}
                {/*        {...register("payment_type")} />*/}
                {/*</Col>*/}
                <Col sm={5} className="">
                    <Form.Check inline className="heading payment-type" label={<span><img src="/images/google-pay.png" alt="Google Pay icon" className="payment-icon google"></img> GOOGLE PAY</span>}
                        name="payment_method" type="radio" id="googlePay" value={3} onClick={altSelect}
                        onChange={e => {
                            payment_type.onChange(e);
                        }}
                        {...register("payment_type")} />
                </Col>
            </Row>


            {/*<Row className="payment-row payment-row-bottom">*/}
            {/*    <Col sm={5}>*/}
            {/*        <Form.Check inline className="heading payment-type" label={<span><img src="/images/apple-pay.png" alt="Apple Pay icon" className="payment-icon apple"></img> APPLE PAY</span>} name="payment_method" type="radio"*/}
            {/*            id="applePay" value={2} onClick={altSelect}*/}
            {/*            onChange={e => {*/}
            {/*                payment_type.onChange(e);*/}
            {/*            }}*/}
            {/*            {...register("payment_type")} />*/}
            {/*    </Col>*/}

            {/*</Row>*/}
            {/*<Row className=" payment-row payment-row-bottom">*/}
            {/*    <Col sm={5}>*/}
            {/*    */}{/*    <Form.Check inline className="heading payment-type" label={<span><img src="https://devbmsstore.ses-bms.com/images/shop-pay.png" alt="Credit card icon" className="payment-icon"></img> SHOP PAY</span>} name="payment_method" type="radio" id="shopPay" value={5} {...register("payment_type")} />*/}
            {/*    </Col>*/}
            {/*    <Col sm={5} className="payment-right">*/}
            {/*    */}{/*    <Form.Check inline className="heading payment-type" label={<span><img src="https://devbmsstore.ses-bms.com/images/amazon-pay.png" alt="Credit card icon" className="payment-icon"></img> AMAZON PAY</span>} name="payment_method" type="radio" id="amazonPay" value={6} {...register("payment_type")} />*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Form.Group className="group mb-3">
                <Form.Check className="heading email-checkbox checkbox spacing-left" type="checkbox" id="emailUpdates" label="SUBSCRIBE TO KAWASAKI EMAIL UPDATES" {...register("subscribe")} />
            </Form.Group>
            <Form.Group className="group mb-3 indent-left">
                <Form.Check type="checkbox" id="terms" label="* I VERIFY THAT I AM 13 OR OVER, A UNITED STATES RESIDENT, AND AGREE TO KAWASAKI'S PRIVACY POLICY AND TERMS AND CONDITIONS OF USE"  {...register("billingTerm")} className={`${errors.billingTerm ? 'form-control is-invalid heading checkbox' : 'heading checkbox'}`} aria-required="true" />
            </Form.Group>

            <Row>
                {<p className="error-msg" id="error2">{(errors.payment_type || errors.fullName
                    || errors.cardNum || errors.expirationDate || errors.cc || errors.billingFirst ||
                    errors.billingLast || errors.billingCity || errors.billingZip || errors.billingStateKey ||
                    errors.billingAddress || errors.billingEmail || errors.billingTerm) ? 'Please correct the highlighted fields above.' : ''}</p>}
                {<p className="error-msg" id="error-msg-one">{errorMsg}</p>}
            </Row>
            <Row className="captcha" id="rowRecaptcha">
                <div className="g-recaptcha checkout-captcha" data-sitekey="6LcVUXwoAAAAAOqMeVMImAmVm3RverIT0gJgkAK-"></div>
            </Row>

            <Button variant="primary" className="submit right" onClick={handleSubmit} type="submit">
                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
                SUBMIT YOUR ORDER</Button>

            <Modal className="loader-div" id="loader" show={pop} onHide={handleClose} size="lg" centered>
                <PulseLoader color="#66cc33" size="20px" margin="12px" />
            </Modal>

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton closeVariant="white"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <Modal.Title>THERE IS A PROBLEM WITH YOUR PAYMENT</Modal.Title>
                </Modal.Header>
                <Modal.Body>{paymentError}</Modal.Body>
                <Modal.Body>Please confirm the payment information and try again.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                        RETURN TO CHECKOUT
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modal} onHide={handleModalClose} size="lg" centered>
                <Modal.Header closeButton closeVariant="white"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <Modal.Title>COMPLETE YOUR ORDER</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>To complete your order, click the payment button below.</div>
                </Modal.Body>
                <Modal.Body>
                    <div>You will be redirected to an external website to complete your payment.</div>
                </Modal.Body>
                <Modal.Footer>
                    {/*Don't delete this ID: Google Pay Button placeholder, Elavon will send stye back.*/}
                    {/*     <div id="googlepay-button"></div>*/}
                    <div className="GoogPayButtonPH" id="container"></div>

                    <div id="applepay-button"></div>

                    <div id="paypal-button"></div>

                    <div id="clicktopay-button"></div>
                </Modal.Footer>
            </Modal>


        </form>


    )
}